import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../design/Enciclopedia.css';
import { API_URL } from '../../config/config';

const Enciclopedia = () => {
  const [categorias, setCategorias] = useState([]);
  const [filteredCategorias, setFilteredCategorias] = useState([]);
  const [searchCategoria, setSearchCategoria] = useState('');
  const [selectedCategoria, setSelectedCategoria] = useState(null);

  const [subcategorias, setSubcategorias] = useState([]);
  const [articulos, setArticulos] = useState([]);
  const [filteredSubcategorias, setFilteredSubcategorias] = useState([]);
  const [searchSubcategoria, setSearchSubcategoria] = useState('');
  const [selectedSubcategoria, setSelectedSubcategoria] = useState(null);

  const [selectedArticulo, setSelectedArticulo] = useState(null);
  const [loadingArticulos, setLoadingArticulos] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editableContent, setEditableContent] = useState('');
  const [editingArticulo, setEditingArticulo] = useState(null);

  const [showFormatted, setShowFormatted] = useState(true); // Estado para alternar entre estilos renderizados y texto plano

  const toggleFormattedView = () => {
    setShowFormatted((prevState) => !prevState); // Cambia el estado de la vista
  }; 

  const openModal = (articulo) => {
    setEditingArticulo(articulo);
    setEditableContent(articulo.contenido); // Inicializa el contenido editable
    setIsModalOpen(true);
  };
  
  const closeModal = () => {
    setIsModalOpen(false);
    setEditingArticulo(null);
  };
  
  const saveChanges = async () => {
    if (!editingArticulo) return;
  
    console.log(`Intentando actualizar el artículo con ID: ${editingArticulo.id}`);
    console.log('Datos enviados:', editableContent);
  
    try {
      const response = await axios.put(`${API_URL}/enciclopedia/${editingArticulo.id}`, {
        contenido: editableContent,
      });
  
      console.log('Respuesta del servidor:', response.data);
  
      // Actualiza el contenido localmente
      setArticulos((prevArticulos) =>
        prevArticulos.map((articulo) =>
          articulo.id === editingArticulo.id
            ? { ...articulo, contenido: editableContent }
            : articulo
        )
      );
  
      alert('Contenido actualizado exitosamente');
      closeModal();
    } catch (error) {
      console.error('Error al actualizar el contenido:', error.response?.data || error.message);
      alert('Ocurrió un error al actualizar el contenido.');
    }
  }; 

  // Fetch categorías
  useEffect(() => {
    const fetchCategorias = async () => {
      try {
        const response = await axios.get(`${API_URL}/categoria`);
        setCategorias(response.data.categorias);
        setFilteredCategorias(response.data.categorias);
      } catch (error) {
        console.error('Error al cargar categorías:', error);
      }
    };
    fetchCategorias();
  }, []);

  useEffect(() => {
    const filtered = categorias.filter((categoria) =>
      categoria.categoria.toLowerCase().includes(searchCategoria.toLowerCase())
    );
    setFilteredCategorias(filtered);
  }, [searchCategoria, categorias]);

  useEffect(() => {
    if (selectedSubcategoria) {
      const fetchArticulos = async () => {
        setLoadingArticulos(true);
        setSelectedArticulo(null); // Limpiar el contenido del artículo
        try {
          const response = await axios.get(
            `${API_URL}/enciclopedia/ArticulosPorIdSubcategoria/${selectedSubcategoria.idsubcategoria}`
          );
          setArticulos(response.data.articulos);
        } catch (error) {
          console.error('Error al cargar artículos:', error);
        } finally {
          setLoadingArticulos(false);
        }
      };
      fetchArticulos();
    }
  }, [selectedSubcategoria]);

  useEffect(() => {
    if (selectedCategoria) {
      const fetchSubcategorias = async () => {
        try {
          const response = await axios.get(`${API_URL}/subcategoria/${selectedCategoria.idcategoria}`);
          setSubcategorias(response.data.subcategorias);
          setFilteredSubcategorias(response.data.subcategorias);
        } catch (error) {
          console.error('Error al cargar subcategorías:', error);
        }
      };
      fetchSubcategorias();
    }
  }, [selectedCategoria]);

  useEffect(() => {
    const filtered = subcategorias.filter((subcategoria) =>
      subcategoria.subcategoria.toLowerCase().includes(searchSubcategoria.toLowerCase())
    );
    setFilteredSubcategorias(filtered);
  }, [searchSubcategoria, subcategorias]);

  const handleCategoriaClick = (categoria) => {
    setSelectedCategoria(categoria);
  };

  const handleSubcategoriaClick = (subcategoria) => {
    setSelectedSubcategoria(subcategoria);
  };

  const handleViewMore = (articulo) => {
    setSelectedArticulo(articulo);
  };

  const handleBackToGrid = () => {
    setSelectedArticulo(null);
  };

  return (
    <>
      <div className="enciclopedia-container">
        <h1>Gramática</h1>
        <div className="enciclopedia-main">
          <div className="enciclopedia-titulos">
            <div className="enciclopedia-buscar">
              <h2>Títulos</h2>
              <h3>Buscar:</h3>
              <input
                placeholder="Buscar categoría..."
                value={searchCategoria}
                onChange={(e) => setSearchCategoria(e.target.value)}
              />
              <div className="enciclopedia-listbox-container">
                <ul className="enciclopedia-listbox">
                  {filteredCategorias.map((categoria) => (
                    <li
                      key={categoria.idcategoria}
                      className={`enciclopedia-listbox-item ${
                        selectedCategoria?.idcategoria === categoria.idcategoria ? 'active' : ''
                      }`}
                      onClick={() => handleCategoriaClick(categoria)}
                    >
                      {categoria.categoria}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div className="enciclopedia-subtitulos">
            <div className="enciclopedia-buscar">
              <h2>Subtítulos</h2>
              <h3>Buscar:</h3>
              <input
                placeholder="Buscar subtítulo..."
                value={searchSubcategoria}
                onChange={(e) => setSearchSubcategoria(e.target.value)}
              />
              <div className="enciclopedia-listbox-container">
                <ul className="enciclopedia-listbox">
                  {filteredSubcategorias.map((subcategoria) => (
                    <li
                      key={subcategoria.idsubcategoria}
                      className={`enciclopedia-listbox-item ${
                        selectedSubcategoria?.idsubcategoria === subcategoria.idsubcategoria ? 'active' : ''
                      }`}
                      onClick={() => handleSubcategoriaClick(subcategoria)}
                    >
                      {subcategoria.subcategoria}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div className="enciclopedia-contenidos">
            {loadingArticulos ? (
              <div className="enciclopedia-loading">Cargando artículos...</div>
            ) : selectedArticulo ? (
              <div className="enciclopedia-article-content">
                <h2>{selectedArticulo.titulo}</h2>
                <h4>{selectedArticulo.subtitulo}</h4>
                <p
                  style={{ whiteSpace: 'pre-wrap' }}
                  dangerouslySetInnerHTML={{ __html: selectedArticulo.contenido }}
                ></p>
                <button onClick={handleBackToGrid}>Volver</button>
              </div>
            ) : (
              <>
                <h2>Artículos</h2>
                <div className="enciclopedia-article-grid">
                  {articulos.map((articulo) => (
                    <div key={articulo.id} className="enciclopedia-article-card">
                      <h3>{articulo.titulo}</h3>
                      <p>{articulo.subtitulo}</p>
                      <button onClick={() => handleViewMore(articulo)}>Ver más</button>
                      {/* <button onClick={() => openModal(articulo)} className="edit-button">Editar</button> */}
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Editar Contenido</h2>
            <div className="modal-toolbar">
              <button onClick={toggleFormattedView} className="toggle-view-button">
                {showFormatted ? 'Ver código HTML' : 'Ver sin renderizado'}
              </button>
            </div>
            {!showFormatted ? (
              // Vista de código HTML con etiquetas dentro del textarea
              <textarea
                value={editableContent}
                onChange={(e) => setEditableContent(e.target.value)}
                rows="10"
                style={{ width: '100%', fontFamily: 'monospace' }}
              ></textarea>
            ) : (
              // Vista renderizada con soporte para saltos de línea
              <div
                className="contenteditable-container"
                contentEditable
                style={{ whiteSpace: 'pre-wrap' }} // Respeta los saltos de línea
                dangerouslySetInnerHTML={{ __html: editableContent }}
                onInput={(e) => setEditableContent(e.currentTarget.innerHTML)} // Actualizar estado al editar
              ></div>
            )}
            <div className="modal-buttons">
              <button onClick={saveChanges} className="save-button">
                Guardar
              </button>
              <button onClick={closeModal} className="cancel-button">
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}



    </>
  );
};

export default Enciclopedia;
