// src/Components/code/Searcher.js
import React from 'react';
import '../design/Comunidad.css';
import Logo from '../../assets/image.png';
import FotoComunidad from '../../assets/comunidad.jpg';

const Comunidad = () => {
  return (
    <div className="comunidad-container">
      <div className="comunidad-main">
        <img src={Logo} alt="Logo" />
        <div className="comunidad-descripcion">
          <h1>Reseña Histórica de la Comunidad Ligüística K'ub'ul</h1>
          <h5>
          La Comunidad Lingüística K'ub'ul, nace  en el año 2001, con el nombre de: 
          Comité de Promoción Maya de Cubulco (COPROMAC) con el propósito  de alcanzar el 
          reconocimiento del idioma materno de los habitantes del municipio de  Cubulco, Baja Verapaz, 
          ante las autoridades competentes del Estado de Guatemala; debido a las injusticias históricas 
          que ha sufrido como pueblo indígena como resultado de la colonización y la desatención de la 
          Academia de Lenguas Mayas de Guatemala a los pueblos originarios de este país.
          Comité de Promoción Maya de Cubulco (COPROMAC) en sus inicios realizó diplomados 
          con maestros y habitantes del municipio de Cubulco para facilitar el 
          dominio de lecto-escritura y cumplir con las normativas de la educación bilingüe 
          intercultural de enseñar a los niños de las escuelas en su propio idioma. Este comité 
          tuvo vida hasta el año 2012.
          </h5>
        </div>
      </div>
      <div className="comunidad-parrafo">
        <h5>
        En el año 2013 el Comité de Promoción Maya de Cubulco (COPROMAC) con la aspiración  
        de alcanzar el reconocimiento del idioma materno de los habitantes del municipio de  
        Cubulco, Baja Verapaz, (K'ub'ul) pasa  a ser una asociación con personería jurídica 
        ampliando la cobertura en la educación de sus habitantes en su propio idioma.
          </h5>
      </div>
      <div className="comunidad-parrafo">
          <h5>
          Las injusticias cometidas por el Estado de Guatemala, en contra del pueblo K'ub'ul al 
          no reconocer su idioma, adquiere una especial gravedad, ya que el idioma materno 
          representa un elemento de identidad para el pueblo K'ub'ul, 
          medio por el que difunde su pensamiento. Finalmente, conscientes de que  uno de los 
          pilares de la libertad de expresión es precisamente el derecho a hablar, y que éste 
          implica necesariamente el derecho de las personas a utilizar el idioma de su elección 
          en la expresión de su pensamiento. La expresión y la difusión de pensamientos e ideas 
          no pueden impedirse, de modo que una restricción de las posibilidades de divulgación 
          representa directamente un límite al derecho de expresarse libremente, dio vida en el 
          año 2021 a la autodeterminación del pueblo K'ub'ul, formando la directiva de la 
          Comunidad Lingüística K'ub'ul, registrado en la municipalidad de Cubulco, con 24 miembros 
          adquiriendo la  personería jurídica. Actualmente se promueve la iniciativa de ley para el 
          reconocimiento del idioma K'ub'ul, ante el Congreso de la República de Guatemala, que cuenta 
          ya con  dictamen favorable de parte de la  Comisión de Pueblos Indígenas del Congreso de la 
          República de Guatemala.
          </h5>
      </div>
      <div className="comunidad-parrafo">
        <h5>
          ¡POR NUESTRA  HISTORIA, POR NUESTRA CULTURA Y POR NUESTRO IDIOMA!
         </h5>
      </div>
      
      <h2>VISIÓN Y MISIÓN DE LA COMUNIDAD LINGÜÍSTICA K'UB'UL</h2>
      <div className="comunidad-mision-vision">
        <div>
          <h2>Misión</h2>
          <h5>
          Promover acciones para el reconocimiento del idioma materno del pueblo maya K'ub'ul y  
          fortalecer la identidad cultural expresada en la indumentaria, gastronomía y música de 
          conformidad con las tradiciones ancestrales. 
          </h5>
        </div>
        <div>
          <h2>Visión</h2>
          <h5>
          Ser una organización que promueva las tradiciones ancestrales del pueblo maya K'ub'ul 
          con base al idioma, la cultura, la educación, la inclusión y la igualdad para la creación 
          de sociedades abiertas y participativas. 
          </h5>
        </div>
      </div>
      <div className="comunidad-parrafo2">
        <div className="comunidad-parrafo3">
          <h2>Los primeros miembros de la junta directiva del año 2001 (COPROMAC)</h2>
          <div className="comunidad-parrafo4">
            <h3>Presidente:</h3>
            <h5>Leonardo Antret Reyes</h5>
          </div>
          <div className="comunidad-parrafo4">
            <h3>Vicepresidente:</h3>
            <h5>Alejandro Raymundo García</h5>
          </div>
          <div className="comunidad-parrafo4">
            <h3>Secretaria:</h3>
            <h5>Gilda Consuelo Ramírez Ramos</h5>
          </div>
          <div className="comunidad-parrafo4">
            <h3>Tesorero:</h3>
            <h5>Mateo Melecio Matías García</h5>
          </div>
          <div className="comunidad-parrafo4">
            <h3>Vocal I:</h3>
            <h5>Lidia Cotzalo Gómez</h5>
          </div>
          <div className="comunidad-parrafo4">
            <h3>Vocal II:</h3>
            <h5>Santiago Juárez Mejía</h5>
          </div>
          <div className="comunidad-parrafo4">
            <h3>Vocal III:</h3>
            <h5>Jacinto Chubajá Santiago</h5>
          </div>
          <div className="comunidad-parrafo4">
            <h3>Vocal IV:</h3>
            <h5>Francisca Hernandez Ramos</h5>
          </div>
          <div className="comunidad-parrafo4">
            <h3>Vocal V:</h3>
            <h5>Gregorio Morente Ramírez</h5>
          </div>
        </div>
        <div className="comunidad-parrafo3">
          <h2>Los miembros de la directiva actualmente de la Comunidad Lingüística K'ub'ul</h2>
            <div className="comunidad-parrafo4">
              <h3>Presidente:</h3>
              <h5>Mateo Melecio Matías García</h5>
            </div>
            <div className="comunidad-parrafo4">
              <h3>Vicepresidente:</h3>
              <h5>Leonardo Antret Reyes</h5>
            </div>
            <div className="comunidad-parrafo4">
              <h3>Secretario:</h3>
              <h5>Edgar Lucío Juárez Mejía</h5>
            </div>
            <div className="comunidad-parrafo4">
              <h3>Prosecretaria:</h3>
              <h5>Marta Olivia Rodríguez Ramos</h5>
            </div>
            <div className="comunidad-parrafo4">
              <h3>Tesorera:</h3>
              <h5>Julia Hernández Mejía</h5>
            </div>
            <div className="comunidad-parrafo4">
              <h3>Protesorero:</h3>
              <h5>Ángel Calo Solomán</h5>
            </div>
            <div className="comunidad-parrafo4">
              <h3>Vocales:</h3>
              <div className="comunidad-parrafo5">
                <h5>1. Alejandro Ortiz Tiña</h5>
                <h5>2. Santos Raymundo Pérez</h5>
                <h5>3. Lázaro Toj Sis</h5>
                <h5>4. Sebastián Ajualip Solomán</h5>
                <h5>5. Raúl Taperia Jerónimo</h5>
                <h5>6. Juan Álvarez Ramos</h5>
                <h5>7. Manuel Morente Rodríguez</h5>
                <h5>8. Benita Reyes calo</h5>
                <h5>9. María Abelina Rodríguez Matías</h5>
                <h5>10. José Camajá Pérez</h5>
                <h5>11. Román Raymundo López</h5>
                <h5>12. Marcos Chiroy Pérez</h5>
                <h5>13. Juan Velazco de la Cruz.</h5>
                <h5>14. Alfredo Primero Camajá</h5>
                <h5>15. Eugenio Gutiérrez Rodríguez</h5>
                <h5>16. Santos López Bacaj</h5>
                <h5>17. Julio Victor Ruíz</h5>
                <h5>18. Adan Sis Calo</h5>
              </div>
            </div>
        </div>
      </div>
      
      <div className="comunidad-integrantes">
        <h1>Integrantes de la Junta Directiva de la Comunidad Lingüística K'ub'ul</h1>
        <img src={FotoComunidad} alt="Logo" />
      </div>
    </div>
    
  );
};

export default Comunidad;
