// src/Components/code/Searcher.js
import React from 'react';
import '../design/Cubulco.css';
import Cubulco from '../../assets/cubulco3.jpg';

const Comunidad = () => {
  return (
    <div className="cubulco-container">
      <div className="cubulco-main">
        <div className="cubulco-descripcion">
          <h1>Historia del Origen del Pueblo de Cubulco</h1>
          <div className="cubulco-parrafo1">
            <img src={Cubulco} alt="Logo" />
            <div>
                <h5>Se sabe de antemano que la civilización Maya, antes y después de la invasión 
                española mantuvo una tradición oral. Tradición que hace posible conocer de alguna
                manera las costumbres, cultura, idioma y por ende, el origen de las diferentes 
                etnias que conforman esta civilización, narrada desde su cosmovisión. 
                De esta manera, se cuenta que los habitantes del municipio de Cubulco, es decir, 
                los aj k’ub’ul Achi como son conocidos hoy, se originaron de los K’iche’ab’ 
                (quichés).</h5>
            </div>
          </div>
            <h5>
                Se cuenta que hace mucho tiempo, una familia de los K’iche’ab’, 
                decidió separarse totalmente de ellos y así quedar absuelta del poder del rey 
                K’iche’. De esta manera, el jefe de esta familia tomó a su esposa, sus hijos y 
                nietos, y se dirigió hacia al norte de K’iche’. Después de caminar por varios 
                días acampó por un tiempo en una montaña alta y con mucha vegetación, debido al 
                cansancio de sus acompañantes, especialmente de las mujeres pues la mayoría de 
                ellas estaban embarazadas; a esta montaña la llamaron: B'elejtz'aq, 
                (que significa nueve construciones). Estando allí, en agradecimiento por la 
                protección recibida durante el viaje, levantó un altar e hizo una ceremonia. 
                El dato curioso es que, las mujeres embarazadas dieron a luz a sus hijos 
                precisamente en el día de la ceremonia. Por esa razón, los aj K’ub’ul y 
                comunidades aledañas hasta hoy día, consideran a la montaña B’elejtz’aq 
                como una montaña sagrada por lo que pasó allí y tanto como el significado 
                de su nombre.
            </h5>
            <h5>
            Después de este acontecimiento, la familia continuó su marcha hacia al norte en busca de un lugar mejor donde vivir. Luego de muchos días de caminata llegaron a un lugar donde pasa un río que lo llamaron: Janima (río grande) lo llamaron Kawinal, (que significa segunda familia) situado al norte del actual municipio de Cubulco. El jefe de familia decidió parar allí, pues pensaba que entre K’iche’ y Kawinal había una distancia de por lo menos ocho días de camino y consideraba que estaba lo suficientemente lejos de la presencia del rey K’iche’. Así fue que decidió establecerse en Kawinal, escondido entre los cerros, en un lugar arinconado. Comenzaron entonces la construcción formal de casas, templos, canchas para el juego de pelota y otras construcciones de piedra. Actualmente esas construcciones aún se conservan en Kawinal, sin embargo, las aguas del embalse de la hidroeléctrica del Chixoy cubre este patrimonio cultural y totalmente abandonado y cada vez se está distruyendo.
            </h5>
            <h5>
            Pasado un tiempo, se dieron cuenta que las condiciones climáticas no eran las adecuadas para continuar viviendo en ese lugar, el clima era cálido, pocas lluvias y las tierras no eran muy fértiles. El jefe de familia, que más tarde formaría la tribu de los aj K’ub’ul decidió buscar nuevos horizontes. Así que decidió moverse nuevamente, esta vez con una familia más numerosa, se dirigieron hacia el sur sobre la serranía, a un lugar que le dieron por nombre Chi Ilb’al Saq (lugar donde se mira la claridad), al oeste de Xib’alb’a y al suroeste de la actual Villa de Cubulco. Sin embargo, allí tampoco le pareció conveniente vivir, debido a que Chi Ilb’al Saq se encuentra en una colina, era el paso del viento frío de la montaña y sobre todo era un lugar vulnerable para cualquier ataque enemigo, por lo que el jefe de familia decidió abandonarla lo más pronto posible. De esa cuenta en Chi Ilb’al Saq se realizaron menos construcciones que en Kawinal, debido a la corta ocupación de este lugar; sin embargo, en la actualidad se pueden encontrar pequeños templos, altares y una cancha de pelota.
            </h5>
            <h5>
            No encontrando las tierras que llenara sus expectativas, el jefe de los aj K’ub’ul regresó al noreste de la región para ver, si esta vez encontraba algo mejor. Lo que buscaba en realidad era unas tierras fértiles para la siembra del maíz, frijol y ayote, y con un clima agradable, templado, para ser específico; no quería un clima tan cálido como en Kawinal, pero tampoco tan frío como en Chi Ilb’al Saq que enferma a los miembros de su familia. Después de un día de camino, se encontraron con un río que lo llamaron Raqana re Xukulik Ab'aj. Este era un lugar plano, con un clima templado, con un río de aguas frescas y cristalinas que bajan de las verdes montañas. Este lugar le pareció muy agradable y aún toda la familia estaba muy feliz de haberla encontrado. Era un ambiente adecuado, de mucha paz y tranquilidad. Esta era la razón por la cual habían emigrado; el jefe de familia de los aj K’ub’ul, ya estaba cansado de las constantes guerras a que eran sometidos por el rey de los K’iche’ y lo que anhelaba era un lugar tranquilo, en donde su preocupación de cada día era el de cuidar y proveer por su familia y no, el de ir a una guerra sin saber si volvería a ver a su familia. Ese día todos los miembros de la familia hicieron un juramento, que no se moverían nunca más en busca de otras tierras, y que esa bendita tierra sería para ellos y para sus descendientes de generación en generación. Y en efecto así fue, los aj K’ub’ul se establecieron en esta bendita tierra que hoy por hoy es la Villa de Cubulco.
            </h5>
            <h5>
            Preocupado el rey K’iche’ por la desersión de este jefe familiar, envió un contingente de guerreros para controlar todos los movimientos de este grupo familiar. Temía que ellos buscaran refuerzos de los otros grupos étnicos para hacer un gran ejército y luego atacarlo. Estos vigilantes se establecieron al oriente de los aj K’ub’ul y como ellos se habían separado por encontrar paz y tranquilidad eran muy pacíficos. Y eso era lo que le informaban al rey K’iche,’ que no se les debe temer pues permanecían en calma, como la quietud de un lago.
            </h5>
            <h5>
            Pero al pasar un tiempo los guerreros vigilantes se dieron cuenta que la vida de los aj K’ub’ul era diferente a los que estaban bajo el mando del rey K’iche’, pues cultivaban sus tierras, y sus cosechas las disfrutaban al lado de sus familias y vivían sin temor de ser atacados en cualquier instante o de ser llamados para la guerra. Les motivó bastante esa forma de vivir que este grupo de guerreros regresó a su lugar de origen Tujalj (Sacapulas, el Quiché), pero sólo para ir a traer a sus esposas e hijos e iniciar una nueva comunidad en el lugar donde fueron asignados para vigilar a los aj K’ub’ul. Este dato nos confirmó el Señor Antonio Rodréguez, originario de Sacapulas, que todavía presenció los contactos que tenían sus padres y tíos con Rabinal, San Miguel Chicaj y Salamá hace sesenta años atrás; porque ellos sabían que eran de las mismas familias. Siempre traían cosas de Sacapulas a estos tres municipios, principalmente las joyas que se fabricaban allá y mientras estaban en Rabinal van en los campos a conseguir trementina aromática de copal pon para llevar a Sacapulas cuando se regresaban. Su ruta siempre es de Canillá, Cubulco y luego llegan al destino; pero nunca se quedaban en Cubulco a pesar de su cercanía a comparación con otros municipios.
            </h5>
            <h5>
            Este contingente de guerreros que se establecieron cerca de los aj K’ub’ul, tuvieron que enfrentarse a los Poqom pues les pertenecía ese territorio, sin embargo, estos guerreros les invadieron sus tierras y sometieron a unos de sus hombres. Por esa razón los Poqom huyeron a las montañas altas de las Verapaces los actuales Poqomchi’, y al sur, los actuales Poqomam (Padre Ricardo Terga) en donde aún se encuentran actualmente. De esta manera los vigilantes se establecieron cerca de los aj K’ub’ul, fuera del dominio del rey K’iche’. Aunque estos vigilantes estaban relativamente cerca de los aj K’ub’ul, no entablaron comunicación con ellos, sino que estuvieron alertas por si los Poqomchi’ regresaban a recuperar sus tierras por medio de una guerra. Estos vigilantes Tujalj son conocidos hoy como los je Rab’in (Rabinalenses).
            </h5>
            <h5>
            Los aj K’ub’ul y los je Rab’in, aunque eran gobernados por el rey de los K’iche’, no son de la misma tribu y por lo tanto, no tienen el mismo idioma ni el mismo traje. Todo esto se detecta a través de la tradición oral, que ambas comunidades que pertenecían al K’iche’ se separaron en diferente tiempo y con diferentes propósito. Por eso no se mantuvieron unidos, ni estuvieron comunicados desde la formación de ambas comunidades, de esa manera cada uno tiene su propia historia, su propia cultura, su propia lengua, sus propias costumbres y tradiciones y cada quien se distingue con su propio traje.
            </h5>
            <h5>
            En el transcurso del tiempo, las nuevas generaciones comenzaron a intercambiar mercancías entre ambas comunidades, que hasta el día de hoy se observan tanto a los je Rab’in como a los aj K’ub’ul que sostienen comunicación con fines comerciales, también por ser la vía de salida hacia el oriente y la capital, los aj K’ub’ul tienen el paso obligado por Rabinal. Sin embargo, aunque existe esta relación comercial cada quien ha guardado, su idioma, traje, tradiciones y costumbres que las hace ser únicas en su género. Lo que se observa en nuestros días, es pues, la confirmación de la tradición oral de nuestros antepasados. (Francisco Luis Tz’unun y Miguel Antret Chom ).
            </h5>
            <h2>
            Otra hipótesis del origen del pueblo de Cubulco
            </h2>
            <h5>
            Cubulco forma parte del complejo de asentamientos mayas Quichés. Sobre sus orígenes Mary Shaw afirma que éste era el más lejano de los pueblos Quichés, cuyos reyes se asentaban en Chijyub’, actualmente aldea cercana al municipio de Santa Cruz del Quiché; se denominaban Achíes y se independizaron formando un nuevo reino, tuvieron un nuevo idioma y forjaron una nueva civilización, conservando la denominación de Achíes.
            Como una estrategia de control los Quichés enviaron a poblar el lugar que se conoce como Rabinal con habitantes del actual municipio de Sacapulas, los que a su vez se independizaron formando el reino Quiché Achi3, según los Anales de los Kaqchikeles, el Pop Wuj y Rabinal Achi.
            </h5>
            <h5>
            El gentilicio Cubulco es un término castellanizado que deriva del Kaqchikel “Kub’ulajay” que significa “casa de Guirnaldas”. Actualmente se acepta el vocablo Quiché “Aj K’ub’ulaja” que también significa “los de la casa de las guirnaldas”. La población de Cubulco tiene sus raíces étnicas en los maya K’iche’ab’ y su comunidad Achi, el nombre de su idioma es una derivación del K’iche’.
            </h5>
            <h2>
            Época hispánica:
            </h2>
            <h5>
            Como centro poblado actual, Cubulco fue fundado en el año 1537 por Fray Francisco Ximenes o Fray Bartolomé de las Casas. Según el título Real de Don Francisco Izquín Nehaíb (CONALFA, 1989) (FLACSO, 1992) se le define como “…una tribu indígena, pagando sus tributos de cacao y sal… “(CONALFA, 1989). Por acuerdo Gubernativo del 18 de abril de 1923, durante el Gobierno de José M. Orellana se le concedió el Título de Villa el 18 de abril de 1923.
            </h5>
            <h5>
            Cubulco es uno de los ocho municipios del departamento de Baja Verapaz, localizado a 49 kilómetros de la cabecera departamental y a 200 kilómetros de la ciudad capital con carretera asfaltada. También es accesible desde el municipio de San Juan Sacatepéquez, por carretera de terracería de Rabinal al Chol y hasta Granados, pero transitables todo el año, en este caso la distancia desde la ciudad capital es de 132 kilómetros.
            Este pueblo está ubicado entre el centro y el norte del país de Guatemala. La población actual del municipio es de 46,909 habitantes, tomando como base el último Censo de 2002, de los cuales la mayoría es indígena de 35,392 y la minoría es ladina de 11,517 habitantes. El pueblo indígena pertenece a la rama K’iche’ de la familia lingüística maya y habla el idioma Achi y el castellano.
            </h5>
            <h5>
            El 6% ha emigrado fuera del departamento, a Flores Petén, a Chisec, a Fray Bartolomé de las Casas, a Santa Catarina La Tinta Alta Verapaz, a Playa Grande Ixcán, en busca de tierra, mejores cultivos y trabajo. El 3% ha emigrado a diferentes municipios del departamento en busca de trabajos y negocios; y el 10% ha emigrado a Estados Unidos en busca del sueño americano. Por lo cual hace un total de 4,004 emigrantes.
            </h5>
            <h5>
            Dentro de los 46,909 habitantes hay 15,949 alfabetos y 30,960 analfabetos, que significa que hay un 66% de analfabetismo en Cubulco.
            </h5>
        </div>
      </div>
    </div>
    
  );
};

export default Comunidad;
