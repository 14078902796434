import React, { useState } from 'react';
import '../design/Searcher.css';

const Searcher = ({ handleSearch }) => {
  const [searchTerm, setSearchTerm] = useState(''); // Estado local para el término de búsqueda
  const [selectedLanguage, setSelectedLanguage] = useState('Castellano');

  const toggleLanguage = () => {
    setSelectedLanguage((prevLanguage) =>
      prevLanguage === 'Castellano' ? "K'ub'ul" : 'Castellano'
    );
  };

  const handleSearchClick = () => {
    const platform = selectedLanguage === 'Castellano' ? 'plataformaDiccionarioCastellano' : 'plataformaDiccionario';
    handleSearch(searchTerm, platform); // Llama al manejador de búsqueda de `Home`
  };

  return (
    <div className="searcher-container">
      <h1 className="searcher-title">Vocabulario K'ub'ul</h1>
      <div className="search-bar">
        <input
          type="text"
          className="search-input"
          placeholder="Buscar..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)} // Actualiza el término de búsqueda
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleSearchClick(); // Permite buscar al presionar Enter
          }}
        />
        <button className="search-button" onClick={handleSearchClick}>
          <span className="material-icons">search</span>
        </button>
      </div>
      <div className="language-selector">
        <select
          className="select-dropdown"
          value={selectedLanguage}
          onChange={(e) => setSelectedLanguage(e.target.value)}
        >
          <option>Castellano</option>
          <option>K'ub'ul</option>
        </select>
        <button className="switch-button" onClick={toggleLanguage}>
          <span className="material-icons">swap_horiz</span>
        </button>
      </div>
    </div>
  );
};

export default Searcher;