import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../design/Diccionario.css';
import { FaFacebook } from 'react-icons/fa';
import { API_URL } from '../../config/config';

const DiccionarioCastellano = () => {
  const [words, setWords] = useState([]);
  const [filteredWords, setFilteredWords] = useState([]);
  const [searchTerm, setSearchTerm] = useState(''); // Texto ingresado en el campo de búsqueda
  const [selectedLetter, setSelectedLetter] = useState(null);
  const [selectedWord, setSelectedWord] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (searchTerm) {
      const foundWord = words.find(
        (word) =>
          word.kubul.toLowerCase() === searchTerm.toLowerCase() ||
          word.castellano.toLowerCase() === searchTerm.toLowerCase()
      );
      setSelectedWord(foundWord || null);
    }
  }, [searchTerm, words]);

  const handleError = (error, customMessage) => {
    console.error(customMessage, error);
    setError(customMessage);
  };

  const fetchPalabras = async () => {
    try {
      const response = await axios.get(`${API_URL}/castellano`);
      setWords(response.data.castellano);
      setFilteredWords(response.data.castellano);
      setLoading(false);
    } catch (error) {
      handleError(error, 'Error al cargar palabras');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPalabras();
  }, []);

  const handleLetterClick = (letter) => {
    if (selectedLetter === letter) {
      setSelectedLetter(null);
      setFilteredWords(words);
    } else {
      setSelectedLetter(letter);
      setFilteredWords(words.filter((word) => word.castellano.toLowerCase().startsWith(letter.toLowerCase())));
    }
  };

  const handleItemClick = (word) => {
    setSelectedWord(word);
  };

  const handleSearch = () => {
    const foundWord = words.find(
      (word) =>
        word.castellano.toLowerCase() === searchTerm.toLowerCase() ||
        word.kubul.toLowerCase() === searchTerm.toLowerCase()
    );
    if (foundWord) {
      setSelectedWord(foundWord);
    } else {
      setSelectedWord(null);
    }
  };

  const speakWord = (text) => {
    if ('speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.lang = 'es-ES'; // Idioma: Español
      window.speechSynthesis.speak(utterance);
    } else {
      alert('Tu navegador no soporta síntesis de voz.');
    }
  };

  return (
    <div className="diccionario-container">
      <h1>Vocabulario Castellano</h1>
      <div className="diccionario-main">
        <aside className="diccionario-sidebar">
          <div className="diccionario-sidebar-section">
            {[
              'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J',
              'K', 'L', 'M', 'N', 'Ñ', 'O', 'P', 'Q', 'R', 'S',
              'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
            ].map((letter) => (
              <button
                key={letter}
                className={`sidebar-button ${selectedLetter === letter ? 'active' : ''}`}
                onClick={() => handleLetterClick(letter)}
              >
                {letter}
              </button>
            ))}
          </div>
          <div className="diccionario-list">
            {loading && <p>Cargando palabras...</p>}
            {error && <p>Error: {error}</p>}
            {!loading && !error && (
              <ul className="listbox">
                {filteredWords.map((word) => (
                  <li
                    key={word.id}
                    className="listbox-item"
                    onClick={() => handleItemClick(word)}
                  >
                    {word.castellano}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </aside>
        <div className="diccionario-body">
          <div className="diccionario-search-bar">
            <input
              type="text"
              className="diccionario-search-input"
              placeholder="Buscar..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSearch(); // Llama a la función de búsqueda al presionar Enter
                }
              }}
            />
            <button className="diccionario-search-button" onClick={handleSearch}>
              Buscar
            </button>
          </div>
          <div className="diccionario-results">
            <main className="diccionario-main-content">
              <div className="diccionario-word-section">
                {selectedWord ? (
                  <>
                    <div className="word">
                      <h1>{selectedWord.castellano}</h1>
                      <h5 className="diccionario-italic">{selectedWord.tipo}</h5>
                      <span
                        className="material-icons diccionario-audio-icon"
                        onClick={() => speakWord(selectedWord.castellano)}
                      >
                        volume_up
                      </span>
                    </div>
                    <hr className="diccionario-divider" />
                    <p>{selectedWord.kubul}</p>
                    <h3>
                      {[selectedWord.descripcion1, selectedWord.tipo1, selectedWord.descripcion2, selectedWord.tipo2]
                        .filter((item) => item)
                        .join(' ')}
                    </h3>
                  </>
                ) : (
                  <p>Selecciona una palabra del listado o usa el buscador.</p>
                )}
              </div>
            </main>
            <aside className="diccionario-word-of-the-day">
              <h3>Síguenos en Facebook</h3>
              <FaFacebook className="diccionario-facebook-icon" />
              <p>Communidad Lingüística K'ub'ul</p>
              <button
                className="diccionario-subscribe-button"
                onClick={() =>
                  window.open(
                    'https://www.facebook.com/profile.php?id=100090026014299&mibextid=LQQJ4d',
                    '_blank'
                  )
                }
              >
                SÍGUENOS!
              </button>
            </aside>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiccionarioCastellano;
