import React, { useState, useRef, useEffect } from 'react';
import '../design/Home.css';
import Logo from '../../assets/image.png';
import Searcher from './Searcher';
import Diccionario from './Diccionario';
import DiccionarioCastellano from './DiccionarioCastellano';
import Comunidad from './Comunidad';
import Enciclopedia from './Enciclopedia';
import Cubulco from './Cubulco';

const Home = () => {
    const [activeMenuItem, setActiveMenuItem] = useState('Overview');
    const [plataformaVisible, setPlataformaVisible] = useState('plataformaSearcher');
    const [searchTerm, setSearchTerm] = useState('');

    const mostrarPlataforma = (plataforma) => {
        setPlataformaVisible(plataforma);
      };

    const handleMenuClick = (menuItem, title, icon) => {
        setActiveMenuItem(menuItem);
        if (menuItem === 'Searcher') {
          mostrarPlataforma('plataformaSearcher');
        } else if(menuItem === 'Diccionario') {
          mostrarPlataforma('plataformaDiccionario');
        } else if(menuItem === 'DiccionarioCastellano') {
          mostrarPlataforma('plataformaDiccionarioCastellano');
        } else if(menuItem === 'Comunidad') {
          mostrarPlataforma('plataformaComunidad');
        } else if(menuItem === 'Enciclopedia') {
          mostrarPlataforma('plataformaEnciclopedia');
        } else if(menuItem === 'Cubulco') {
          mostrarPlataforma('plataformaCubulco');
        }
      };
    
      const handleSearch = (term, platform) => {
        setSearchTerm(term); // Guarda el término de búsqueda
        mostrarPlataforma(platform); // Cambia a la plataforma deseada
      };

  return (
    <div className="home-container">
      {/* Encabezado principal */}
      <header className="header">
        <div className="header-logo">
          <img src={Logo} alt="Logo" />
          <span>Comunidad Lingüística K'ub'ul</span>
        </div>
      </header>

      {/* Sub-encabezado para enlaces */}
      <div className="sub-header">
        <div className="sub-header-links">
        <a href="#Inicio" className="sub-header-link" onClick={() => handleMenuClick('Searcher')}>Inicio</a>
          <a href="#comunidad" className="sub-header-link" onClick={() => handleMenuClick('Comunidad')}>Comunidad Lingüística</a>
          <a href="#monografia" className="sub-header-link" onClick={() => handleMenuClick('Cubulco')}>Cubulco B. V.</a>
          <a href="#vocabulario" className="sub-header-link" onClick={() => handleMenuClick('Diccionario')}>Vocabulario K'ub'ul</a>
          <a href="#vocabulario" className="sub-header-link" onClick={() => handleMenuClick('DiccionarioCastellano')}>Vocabulario Castellano</a>
          <a href="#enciclopedia" className="sub-header-link" onClick={() => handleMenuClick('Enciclopedia')}>Gramática</a>
          {/* <a href="#nosotros" className="sub-header-link">Colaboradores</a> */}
          {/* <a href="#ayuda" className="sub-header-link">Ayuda</a> */}
        </div>
      </div>

      {/* Slider */}
       <div className="body-page">
        {plataformaVisible === 'plataformaSearcher' && (
          <Searcher
            setPlataformaVisible={setPlataformaVisible}
            handleSearch={handleSearch} // Pasar la función para manejar la búsqueda
          />
        )}
        {plataformaVisible === 'plataformaDiccionario' && (
          <Diccionario searchTerm={searchTerm} /> // Pasar el término de búsqueda
        )}
        {plataformaVisible === 'plataformaDiccionarioCastellano' && (
          <DiccionarioCastellano searchTerm={searchTerm} /> // Pasar el término de búsqueda
        )}
        {plataformaVisible === 'plataformaComunidad' && <Comunidad />}
        {plataformaVisible === 'plataformaEnciclopedia' && <Enciclopedia />}
        {plataformaVisible === 'plataformaCubulco' && <Cubulco />}
      </div>
    </div>
  );
};

export default Home;
