import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../design/Diccionario.css';
import { FaFacebook } from 'react-icons/fa';
import { API_URL } from '../../config/config';

const Diccionario = () => {
  const [words, setWords] = useState([]);
  const [filteredWords, setFilteredWords] = useState([]);
  const [searchTerm, setSearchTerm] = useState(''); // Estado para el texto ingresado en el campo de búsqueda
  const [selectedChar, setSelectedChar] = useState(null);
  const [selectedWord, setSelectedWord] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (searchTerm) {
      const foundWord = words.find(
        (word) =>
          word.kubul.toLowerCase() === searchTerm.toLowerCase() ||
          word.castellano.toLowerCase() === searchTerm.toLowerCase()
      );
      setSelectedWord(foundWord || null);
    }
  }, [searchTerm, words]);
  
  useEffect(() => {
    const fetchWords = async () => {
      try {
        const response = await axios.get(`${API_URL}/diccionario`);
        setWords(response.data.diccionario);
        setFilteredWords(response.data.diccionario); // Inicializa con todas las palabras
        setLoading(false);
      } catch (err) {
        console.error('Error al cargar palabras:', err);
        setError('Error al cargar palabras');
        setLoading(false);
      }
    };

    fetchWords();
  }, []);

  const handleCharClick = (char) => {
    if (selectedChar === char) {
      setSelectedChar(null);
      setFilteredWords(words);
    } else {
      setSelectedChar(char);
      setFilteredWords(
        words.filter((word) => word.kubul.toLowerCase().startsWith(char.toLowerCase()))
      );
    }
  };

  const handleItemClick = (word) => {
    setSelectedWord(word);
  };

  const handleSearch = () => {
    const foundWord = words.find(
      (word) =>
        word.kubul.toLowerCase() === searchTerm.toLowerCase() ||
        word.castellano.toLowerCase() === searchTerm.toLowerCase()
    );
    if (foundWord) {
      setSelectedWord(foundWord);
    } else {
      setSelectedWord(null); // Si no se encuentra, muestra el mensaje de "sin resultados"
    }
  };

  const playAudio = (audioFile) => {
    if (audioFile) {
      const audio = new Audio(`/audios/${audioFile}`);
      audio.play().catch((error) => {
        console.error('Error reproduciendo el audio:', error);
        alert('No se pudo reproducir el audio.');
      });
    } else {
      alert('No hay archivo de audio disponible.');
    }
  };

  return (
    <div className="diccionario-container">
      <h1>Vocabulario K'ub'ul</h1>
      <div className="diccionario-main">
        <aside className="diccionario-sidebar">
          <div className="diccionario-sidebar-section">
            {[
              "'", 'A', "B'", 'Ch', "Ch'", 'E', 'I', 'J', 'K', "K'", 'L',
              'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', "T'", 'Tz', "Tz'",
              'U', 'W', 'X', 'Y', '*',
            ].map((char) => (
              <button
                key={char}
                className={`sidebar-button ${selectedChar === char ? 'active' : ''}`}
                onClick={() => handleCharClick(char)}
              >
                {char}
              </button>
            ))}
          </div>
          <div className="diccionario-list">
            {loading && <p>Cargando palabras...</p>}
            {error && <p>Error: {error}</p>}
            {!loading && !error && (
              <ul className="listbox">
                {filteredWords.map((word) => (
                  <li
                    key={word.id}
                    className="listbox-item"
                    onClick={() => handleItemClick(word)}
                  >
                    {word.kubul}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </aside>
        <div className="diccionario-body">
          <div className="diccionario-search-bar">
            <input
              type="text"
              className="diccionario-search-input"
              placeholder="Buscar..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)} // Actualiza el término de búsqueda
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSearch(); // Llama a la función de búsqueda al presionar Enter
                }
              }}
            />
            <button className="diccionario-search-button" onClick={handleSearch}>
              Buscar
            </button>
          </div>
          <div className="diccionario-results">
            <main className="diccionario-main-content">
              <div className="diccionario-word-section">
                {selectedWord ? (
                  <>
                    <div className="word">
                      <h1>{selectedWord.kubul}</h1>
                      <h5 className="diccionario-italic">{selectedWord.tipo}</h5>
                      <span
                        className="material-icons diccionario-audio-icon"
                        onClick={() => playAudio(selectedWord.audio)}
                      >
                        volume_up
                      </span>
                    </div>
                    <hr className="diccionario-divider" />
                    <p>{selectedWord.castellano}</p>
                    <h3>{selectedWord.descripcion}</h3>
                  </>
                ) : (
                  <p>{searchTerm ? 'Sin resultados' : 'Selecciona una palabra del listado.'}</p>
                )}
              </div>
            </main>
            <aside className="diccionario-word-of-the-day">
              <h3>Síguenos en Facebook</h3>
              <FaFacebook className="diccionario-facebook-icon" />
              <p>Communidad Lingüística K'ub'ul</p>
              <button
                className="diccionario-subscribe-button"
                onClick={() =>
                  window.open(
                    'https://www.facebook.com/profile.php?id=100090026014299&mibextid=LQQJ4d',
                    '_blank'
                  )
                }
              >
                SÍGUENOS!
              </button>
            </aside>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Diccionario;
